import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CopyButton } from "./Buttons";

function ShareView({ theme, link, setLink, open, setOpen }) {
  const [buttonText, setButtonText] = useState("Copy");
  const handleClose = () => {
    setOpen(false);
    setButtonText("Copy");
    setLink("");
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Backdrop
      sx={(theme) => ({
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
        backdropFilter: "blur(2px)",
      })}
      open={open}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Paper
          sx={{
            backgroundColor: theme.backgroundColor,
            borderRadius: 4,
            width: "40vw",
            padding: "0 24px 24px 24px",
            position: "relative",
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            sx={{ paddingTop: "8px" }}
          >
            <Typography
              align="left"
              fontWeight="bold"
              color={theme.headingTextColor}
              fontSize={16}
            >
              Share Project
            </Typography>
            <IconButton
              onClick={handleClose}
              disableRipple
              sx={{ marginRight: "-8px" }}
            >
              <CloseIcon sx={{ color: theme.secondaryButtonColor }} />
            </IconButton>
          </Stack>

          <Divider
            sx={{
              borderColor: theme.borderColor,
              width: "calc(100% + 48px)",
              marginLeft: "-24px",
              marginRight: "-24px",
            }}
          />

          <Stack sx={{ paddingTop: "24px" }} direction="row" spacing={1}>
            <TextField
              fullWidth
              placeholder="Generating permalink..."
              value={link}
              size="small"
              InputProps={{
                sx: {
                  color: theme.secondaryTextColor,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.borderColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.borderColor,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.borderColor,
                  },
                },
              }}
              inputProps={{
                readOnly: true,
                sx: {
                  "&::placeholder": {
                    color: "gray",
                  },
                },
              }}
            />
            <CopyButton theme={theme} onClick={copyToClipboard} buttonText={buttonText} setButtonText={setButtonText}/>
          </Stack>
        </Paper>
      </Box>
    </Backdrop>
  );
}

export default ShareView;
