import React from "react";
import { Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import CodeMirror from "@uiw/react-codemirror";
import { indentUnit } from "@codemirror/language";
import { cpp, cppLanguage } from "@codemirror/lang-cpp";
import { rust, rustLanguage } from "@codemirror/lang-rust";
import { languages } from "@codemirror/language-data";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RunButton } from "../UI/Buttons";
import { basicSetupOptions } from "../UI/static";

function PermalinkEditor({
  privateInput,
  gist,
  setGist,
  gistName,
  setCodeOutput,
  isRunning,
  setIsRunning,
  theme,
}) {
  const onChange = (value) => {
    setGist(value);
  };

  const runCode = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: [gist],
      task: "run",
      stdin: privateInput,
      language: gistName.slice(-1) === "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      process.env.REACT_APP_BACKEND_LINK + "/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 8px 0 16px"
        sx={{
          backgroundColor: theme.backgroundColor,
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.headingTextColor,
          }}
        >
          {gistName}
        </Typography>
        <Tooltip
          title="Run Code"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -15],
                  },
                },
              ],
            },
          }}
        >
          <span>
            <RunButton
              onClick={runCode}
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              theme={theme}
            />
          </span>
        </Tooltip>
      </Stack>

      <Divider sx={{ borderColor: theme.borderColor }} />

      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <CodeMirror
          value={gist}
          placeholder={"Please enter your code here..."}
          height="75vh"
          basicSetup={basicSetupOptions}
          theme={theme.codeEditorTheme}
          autoFocus={true}
          onChange={onChange}
          extensions={[
            gistName.slice(-1) === "c"
              ? cpp({ base: cppLanguage, codeLanguages: languages })
              : rust({ base: rustLanguage, codeLanguages: languages }),
            indentUnit.of("    "),
          ]}
        />
      </Box>
    </Box>
  );
}

export default PermalinkEditor;
