import React, { useState } from "react";
import { Box, Chip, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MenuIcon from "@mui/icons-material/Menu";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckIcon from "@mui/icons-material/Check";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  padding: 0,
});

export function ImportInputButton({ onClick, size = "medium", theme }) {
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <IconButton
        disableRipple
        color="inherit"
        aria-label="menu"
        onClick={handleButtonClick}
      >
        <UploadFileIcon
          fontSize={size}
          sx={{ color: theme.secondaryButtonColor }}
        />
      </IconButton>
      <VisuallyHiddenInput
        ref={fileInputRef}
        type="file"
        onChange={onClick}
        multiple
      />
    </>
  );
}

export function MenuButton({ onClick, size = "medium", theme }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="menu"
      onClick={onClick}
    >
      <MenuIcon fontSize={size} sx={{ color: theme.secondaryButtonColor }} />
    </IconButton>
  );
}

export function RunButton({
  onClick,
  size = "medium",
  isRunning,
  setIsRunning,
  theme,
}) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="run"
      onClick={handleClick}
      disabled={isRunning}
      sx={{
        color: theme.primaryColor,
        opacity: isRunning ? 0.5 : 1,
        pointerEvents: isRunning ? "none" : "auto",
        ...(theme.isDarkMode &&
          isRunning && {
          color: "red",
          opacity: 0.5,
          pointerEvents: "none",
        }),
      }}
    >
      <PlayArrowIcon
        fontSize={size}
        sx={{
          color: theme.primaryColor,
          opacity: isRunning ? 0.5 : 1,
          pointerEvents: isRunning ? "none" : "auto",
          ...(theme.isDarkMode &&
            isRunning && {
            opacity: 0.5,
            pointerEvents: "none",
          }),
        }}
      />
    </IconButton>
  );
}

export function CreateFileButton({ onClick, size = "medium", theme }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="create-file"
      onClick={onClick}
      sx={{ margin: 0, padding: 0 }}
    >
      <AddIcon fontSize={size} sx={{ color: theme.secondaryButtonColor }} />
    </IconButton>
  );
}

export function ReportIssueButton({ onClick, theme }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="help"
      onClick={onClick}
    >
      <HelpOutlineIcon
        sx={{
          height: "15px",
          width: "15px",
          color: theme.secondaryButtonColor,
        }}
      />
    </IconButton>
  );
}

export function ImportButton({ onClick, theme }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="import"
      onClick={onClick}
    >
      <Box
        component="img"
        src={theme.iconDir + "/upload_icon.png"}
        alt="Upload icon"
        sx={{
          height: 25,
          width: 25,
          mr: 1,
        }}
      />
    </IconButton>
  );
}

export function ProveButton({ onClick, isRunning, setIsRunning, theme }) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <Button
      variant="contained"
      disabled={isRunning}
      sx={{
        color: "#FFFFFF",
        backgroundColor: theme.primaryColor,
        borderColor: theme.primaryColor,
        fontSize: 14,
        borderRadius: "5px",
        padding: "2px 8px",
        textTransform: "none",
        boxShadow: "none",
        minWidth: "auto",
        margin: 0,
        "&:hover": {
          boxShadow: "none",
          borderColor: theme.primaryColor,
        },
        ...(theme.isDarkMode && {
          "&.Mui-disabled": {
            backgroundColor: "gray",
          },
        }),
      }}
      onClick={handleClick}
      disableRipple
    >
      Prove
    </Button>
  );
}

export function VerifyButton({ onClick, isRunning, setIsRunning, theme }) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <Button
      variant="outlined"
      disabled={isRunning}
      sx={{
        color: theme.primaryColor,
        borderColor: theme.primaryColor,
        fontSize: 14,
        borderRadius: "5px",
        padding: "2px 8px",
        textTransform: "none",
        boxShadow: "none",
        minWidth: "auto",
        margin: 0,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "transparent",
          color: theme.primaryColor,
          borderColor: theme.primaryColor,
        },
        ...(theme.isDarkMode && {
          "&.Mui-disabled": {
            backgroundColor: "gray",
            borderColor: "gray",
          },
        }),
      }}
      onClick={handleClick}
      disableRipple
    >
      Verify
    </Button>
  );
}

export function ShareButton({ onClick, theme }) {
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: theme.primaryColor,
        color: "#FFFFFF",
        padding: "4px 8px",
        borderRadius: 1,
      }}
      onClick={onClick}
    >
      <Box
        component="img"
        src={theme.iconDir + "/share_icon.png"}
        alt="Share icon"
        sx={{
          height: 15,
          width: 15,
          mr: 1,
        }}
      />
      Share
    </Button>
  );
}

export function CopyButton({ buttonText, setButtonText, onClick, theme }) {
  const handleClick = () => {
    setButtonText("Copied!");
    onClick();
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: theme.primaryColor,
        display: "flex",
        alignItems: "center",
        gap: "8px",
        textTransform: "none",
        boxShadow: "none",
        minWidth: "auto",
        margin: 0,
        padding: "4px 8px",
        "&:hover": {
          boxShadow: "none",
        },
      }}
      onClick={handleClick}
      disableRipple
    >
      <ContentCopyIcon sx={{ height: "16px", width: "16px" }} />
      {buttonText}
    </Button>
  );
}

export function DownloadButton({ onClick, isRunning, setIsRunning, theme }) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="download"
      onClick={handleClick}
      disabled={false}
    >
      <Box
        component="img"
        src={theme.iconDir + "/download_icon.png"}
        alt="Download icon"
        sx={{
          height: 30,
          width: 30,
          filter: isRunning ? "grayscale(1)" : null,
        }}
      />
    </IconButton>
  );
}

export function MultiFileButton({ onClick }) {
  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Checkbox
          defaultChecked
          sx={{ height: 16, width: 16 }}
          color="default"
        />
      }
      label={<span style={{ fontSize: 14, marginRight: 4 }}>Multifile</span>}
      disableRipple
    />
  );
}