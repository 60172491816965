import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Snackbar,
} from "@mui/material";
import { CreateFileButton, ImportButton } from "../UI/Buttons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function FileDrawer({
  handleFileClick,
  selectedFile,
  setSelectedFile,
  fileContents,
  setFileContents,
  currentTheme,
}) {
  const [files, setFiles] = useState([
    "reverse.c",
    "checksum.c",
    "sha256.c",
    "fibonacci.rs",
    "fizzbuzz.rs",
    "palindrome.rs",
    "conway.rs",
    "lib.rs"
  ]);
  const [newFileName, setNewFileName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renamedFile, setRenamedFile] = useState(null);
  const [hoveredFile, setHoveredFile] = useState(null);
  const [warning, setWarning] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [temporaryFile, setTemporaryFile] = useState(null);
  const MAX_FILES = 12;

  const handleAddFile = () => {
    setIsAdding(true);
    setTemporaryFile(newFileName);
  };

  const handleFileNameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const handleFileNameSubmit = (e) => {
    if (e.key === "Enter" && newFileName.trim() !== "") {
      const newFile = newFileName.trim();
      if (/^[\w\-\.]+\.(c|rs)$/.test(newFile)) {
        setFiles((prevFiles) => {
          if (!prevFiles.includes(newFile)) {
            return [...prevFiles, newFile];
          }
          return prevFiles;
        });
        setNewFileName("");
        setIsAdding(false);
        handleFileClick(newFile);
        setWarning("");
      } else {
        setWarning("Invalid file type. Please add a Rust of C file");
        setSnackbarOpen(true);
      }
    }
  };

  const handleRenameSubmit = (e) => {
    if (e.key == "Enter" && newFileName.trim() !== "") {
      const newFile = newFileName.trim();
      if (/^[\w\-\.]+\.(c|rs)$/.test(newFile)) {
        setFiles((prevFiles) => {
          const prevIndex = prevFiles.findIndex((prevFile) => prevFile === renamedFile);

          if (prevIndex !== -1) {
            const updatedFiles = [...prevFiles];
            updatedFiles[prevIndex] = newFile;
            return updatedFiles;
          } else {
            if (!prevFiles.includes(newFile)) {
              return [...prevFiles, newFile];
            }
            return prevFiles;
          }
        });

        const oldContents = fileContents[renamedFile];
        setFileContents((prevContents) => {
          const updatedContents = { ...prevContents };
          delete updatedContents[renamedFile];
          updatedContents[newFile] = oldContents;
          return updatedContents;
        });

        setNewFileName("");
        setIsRenaming(false);
        setSelectedFile(newFile);
        setWarning("");
      } else {
        setWarning("Invalid file type. Please add a Rust of C file");
        setSnackbarOpen(true);
      }
    } else if (e.key === "Escape") {
      setNewFileName("");
      setIsRenaming(false);
      setSelectedFile(renamedFile);
      setWarning("");
    }
  }

  const handleBlur = () => {
    setFiles((prevFiles) => prevFiles.map((file) => (file === temporaryFile ? renamedFile : file)));
    setNewFileName("");
    setTemporaryFile(null);
    setIsRenaming(false);
    setIsAdding(false);
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files.filter((file) => file !== fileName);
    setFiles(updatedFiles);

    if (fileName === selectedFile && updatedFiles.length > 0) {
      handleFileClick(updatedFiles[0]);
    } else if (updatedFiles.length === 0) {
      handleFileClick("");
    }
  };

  const handleRenameFile = (prevFileName) => {
    setIsRenaming(true);
    setRenamedFile(prevFileName);
  };

  const onDrop = (acceptedFiles) => {
    const fileContentsMap = {};

    acceptedFiles.forEach((file) => {
      const fileType = file.name.split(".").pop();
      if (fileType === "c" || fileType === "rs") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          fileContentsMap[file.name] = fileContent;

          setFiles((prevFiles) => {
            if (!prevFiles.includes(file.name)) {
              return [...prevFiles, file.name];
            }
            return prevFiles;
          });

          setFileContents((prevContents) => ({
            ...prevContents,
            ...fileContentsMap,
          }));

          handleFileClick(file.name, fileContent);
          setWarning("");
        };
        reader.readAsText(file);
      } else {
        setWarning("Invalid file type. Please upload .c or .rs files.");
        setSnackbarOpen(true);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".c, .rs",
    multiple: true,
    directory: true,
  });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage =
        "You have unsaved changes. Are you sure you want to leave?";
      e.preventDefault();
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: "0 8px",
          backgroundColor: currentTheme.sectionHeadingBackgroundColor,
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <Tooltip
          title="Import File"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -15],
                  },
                },
              ],
            },
          }}
        >
          <span>
            <ImportButton theme={currentTheme} />
          </span>
        </Tooltip>
        <input
          {...getInputProps({
            webkitdirectory: "true",
            multiple: true,
          })}
          style={{ display: "none" }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginRight: 2,
            color: currentTheme.headingTextColor,
          }}
        >
          Files
        </Typography>
        <Tooltip
          title="Create New File"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            },
          }}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              if (files.length === MAX_FILES) {
                setWarning("Maximum file limit reached -- please remove some files");
                setSnackbarOpen(true);
                return;
              }
              handleAddFile();
            }}
          >
            <span style={{ paddingRight: "4px" }}>
              <CreateFileButton theme={currentTheme} />
            </span>
          </span>
        </Tooltip>
      </Stack>

      <Divider sx={{ borderColor: currentTheme.borderColor }} />

      <List>
        {files.map((fileName) => (
          <ListItem
            button
            key={fileName}
            onClick={() => handleFileClick(fileName)}
            onMouseEnter={() => setHoveredFile(fileName)}
            onMouseLeave={() => setHoveredFile(null)}
            style={{
              padding: "4px 4px",
              backgroundColor: fileName === selectedFile ? currentTheme.selectedFileBackgroundColor : null
            }}
          >
            {isRenaming && renamedFile === fileName ? (
              <ListItem>
                <TextField
                  fullWidth
                  autoFocus
                  placeholder="Enter file name"
                  value={newFileName}
                  onBlur={handleBlur}
                  onChange={handleFileNameChange}
                  onKeyDown={handleRenameSubmit}
                  size="small"
                  InputProps={{
                    sx: {
                      color: currentTheme.secondaryTextColor,
                    },
                  }}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        color: "gray",
                      },
                    },
                  }}
                />
              </ListItem>
            ) : (
              <>
                <ListItemText
                  primary={fileName}
                  sx={{
                    textAlign: "center",
                    color:
                      selectedFile === fileName
                        ? currentTheme.primaryColor
                        : currentTheme.secondaryTextColor,
                  }}
                />
                {hoveredFile === fileName && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRenameFile(fileName);
                      }}
                      size="small"
                      style={{ position: "absolute", right: "32px" }}
                      disableRipple
                    >
                      <EditIcon sx={{ color: currentTheme.secondaryTextColor }} />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFile(fileName);
                      }}
                      size="small"
                      style={{ position: "absolute", right: "8px" }}
                      disableRipple
                    >
                      <DeleteIcon sx={{ color: currentTheme.secondaryTextColor }} />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </ListItem>
        ))}
        {isAdding && (
          <ListItem>
            <TextField
              fullWidth
              autoFocus
              placeholder="Enter file name"
              value={newFileName}
              onBlur={handleBlur}
              onChange={handleFileNameChange}
              onKeyDown={handleFileNameSubmit}
              size="small"
              InputProps={{
                sx: {
                  color: currentTheme.secondaryTextColor,
                },
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "gray",
                  },
                },
              }}
            />
          </ListItem>
        )}
      </List>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={warning}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
    </>
  );
}

export default FileDrawer;
