import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Octokit } from "octokit";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../Containers/Header";
import PermalinkEditor from "../Containers/PermalinkEditor";
import InputEditor from "../Containers/InputEditor";
import OutputViewer from "../Containers/OutputViewer";

function PermalinkView({ theme, setCurrentTheme }) {
  const { gistId } = useParams();
  const [gist, setGist] = useState("");
  const [gistName, setGistName] = useState("");
  const [gistLanguage, setGistLanguage] = useState("");
  const [privateInput, setPrivateInput] = useState("");
  const [codeOutput, setCodeOutput] = useState("");
  const [isRunning, setIsRunning] = useState(false);

  const octokit = new Octokit({
    auth: process.env.REACT_APP_GITHUB_TOKEN,
  });

  const fetchGist = async () => {
    try {
      const response = await octokit.request("GET /gists/{gist_id}", {
        gist_id: gistId,
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });

      console.log(response.data.files);

      const codeFile = Object.values(response.data.files).find(
        (file) => !file.filename.endsWith(".txt"),
      );

      const inputFile = Object.values(response.data.files).find((file) =>
        file.filename.endsWith(".txt")
      );
      
      const content = codeFile.content;
      const inputContent = inputFile ? inputFile.content : "";
      const name = codeFile.filename;
      const language = codeFile.language;

      setGist(content);
      setGistName(name);
      setGistLanguage(language);
      setPrivateInput(inputContent);
    } catch (error) {
      console.error("Error fetching gist:", error);
    }
  };

  useEffect(() => {
    fetchGist();
  }, [gistId]);

  return (
    <Stack
      direction="column"
      spacing={0.1}
      sx={{
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "94vw",
        }}
      >
        <Header
          codeValue={gist}
          filename={gistName}
          currentTheme={theme}
          setCurrentTheme={setCurrentTheme}
        />
      </Box>

      <Divider sx={{ color: "#FFFFFF" }} />

      <Paper
        sx={{
          backgroundColor: theme.accentBackgroundColor,
          borderRadius: 0,
          boxShadow: 0,
          padding: 1,
          width: "90vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8} sx={{ width: "100%" }}>
            <PermalinkEditor
              privateInput={privateInput}
              gist={gist}
              setGist={setGist}
              gistName={gistName}
              setCodeOutput={setCodeOutput}
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              theme={theme}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
              <Box sx={{ height: "50%" }}>
                <InputEditor
                  privateInput={privateInput}
                  setPrivateInput={setPrivateInput}
                  maxRows={10}
                  currentTheme={theme}
                />
              </Box>
              <Box sx={{ height: "50%" }}>
                <OutputViewer
                  privateInput={privateInput}
                  codeValue={gist}
                  selectedFile={gistName}
                  codeOutput={codeOutput}
                  setCodeOutput={setCodeOutput}
                  isRunning={isRunning}
                  setIsRunning={setIsRunning}
                  maxRows={12}
                  currentTheme={theme}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
}

export default PermalinkView;
