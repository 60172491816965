import * as React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function LanguageSwitcher({ filename, theme }) {
  const language = filename.slice(-1) === "c" ? "C/C++" : "RUST";

  return (
    <Paper
      elevation={0}
      spacing={0}
      sx={{
        borderRadius: "24px",
        backgroundColor: theme.secondaryBackgroundColor,
      }}
    >
      <Stack
        direction="row"
        spacing={1.25}
        justifyContent="space-between"
        alignItems="center"
        padding="4px 10px"
      >
        <Typography sx={{ fontSize: 14, color: theme.secondaryTextColor }}>
          Language
        </Typography>
        <Typography sx={{ fontSize: 14, color: theme.primaryColor }}>
          {language}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default LanguageSwitcher;
