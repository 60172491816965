import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";

function WelcomeView({ theme }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop
      sx={(theme) => ({
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
        backdropFilter: "blur(2px)",
      })}
      open={open}
    >
      <Paper
        sx={{
          backgroundColor: theme.backgroundColor,
          borderRadius: 4,
          width: "40vw",
          height: "auto",
          padding: "24px",
          position: "relative",
        }}
      >
        <Stack spacing={2} alignItems="center">
          <Box
            component="img"
            src={theme.iconDir + "/lita_logo.png"}
            alt="Lita icon"
            sx={{
              height: 35,
              width: 35,
              mr: 1,
            }}
          />
          <Typography
            sx={{
              fontSize: 21,
              color: theme.headingTextColor,
              textAlign: "center",
            }}
          >
            <strong>Welcome to Valida Playground!</strong>
          </Typography>
          <Stack
            spacing={1}
            alignItems="flex-start"
            sx={{ width: "100%", textAlign: "left", paddingLeft: "16px" }}
          >
            <Typography color={theme.headingTextColor}>
              <strong>Step 1:</strong> Write code in Rust / C (more coming soon)
            </Typography>
            <Typography color={theme.headingTextColor}>
              <strong>Step 2:</strong> Run your code with various inputs
            </Typography>
            <Typography color={theme.headingTextColor}>
              <strong>Step 3:</strong> Prove and verify the execution of your
              code
            </Typography>
            <Typography color={theme.headingTextColor}>
              Note: For multi-file compilation, name your secondary file <code style={{ color: theme.primaryColor }}>lib.rs</code> and assume the root of the crate to be <code style={{ color: theme.primaryColor }}>rust_shell</code>.
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            sx={{
              color: theme.headingTextColor,
              borderColor: theme.borderColor,
              width: "40vw",
              backgroundColor: theme.accentBackgroundColor,
              textTransform: "none",
            }}
            onClick={handleClose}
            disableRipple
          >
            Continue
          </Button>
        </Stack>
      </Paper>
    </Backdrop>
  );
}

export default WelcomeView;
