import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Octokit } from "octokit";
import { ReportIssueButton, ShareButton } from "../UI/Buttons";
import LanguageSwitcher from "../UI/LanguageSwitcher";
import MachineInfo from "../UI/MachineInfo";
import DarkModeToggle from "../UI/DarkModeToggle";
import ShareView from "../UI/ShareView";
import CompilerVersion from "../UI/CompilerVersion";

const octokit = new Octokit({
  auth: process.env.REACT_APP_GITHUB_TOKEN,
});

function Header({
  codeValue,
  privateInput,
  filename,
  currentTheme,
  setCurrentTheme,
}) {
  const [link, setLink] = useState("");
  const [clickedShare, setClickedShare] = useState(false);
  const inverseTheme = currentTheme.name === "light" ? "Dark" : "Light";

  const handleShare = async () => {
    setClickedShare(true);
    try {
      console.log(privateInput);
      const response = await octokit.request("POST /gists", {
        public: false,
        description: "",
        files: {
          [filename]: {
            content: codeValue,
          },
          ...(privateInput ? { "inputs.txt": { content: privateInput } } : {}),
        },
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });

      console.log("Gist created successfully:", response.data);

      const gistUrl = response.data.html_url;
      const gistId = gistUrl.split("/").pop();
      const link = window.location.href + gistId;
      setLink(link);
    } catch (error) {
      console.error("Error creating gist:", error);
    }
  };

  const handleReportIssue = () => {
    window.open(
      "https://github.com/lita-xyz/llvm-valida-releases/issues",
      "_blank",
    );
  };

  return (
    <header
      className="app-header"
      backgroundColor={currentTheme.backgroundColor}
    >
      <ShareView
        theme={currentTheme}
        link={link}
        setLink={setLink}
        open={clickedShare}
        setOpen={setClickedShare}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        {/* LHS of Header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <a
            href="https://www.lita.foundation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={currentTheme.iconDir + "/lita_logo.png"}
              alt="Logo"
              sx={{ height: 35, width: 35, padding: "0 4px" }}
            />
          </a>
          <Chip
            label="PLAYGROUND"
            sx={{
              fontSize: "10px",
              height: "20px",
              minWidth: "auto",
              color: currentTheme.headingTextColor,
              backgroundColor: currentTheme.accentBackgroundColor,
            }}
          />
          <Tooltip
            title="Report Issue"
            placement="right"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <ReportIssueButton
                onClick={handleReportIssue}
                theme={currentTheme}
              />
            </span>
          </Tooltip>
        </Stack>

        {/* RHS of Header */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip title={inverseTheme + " Mode"} placement="left">
            <span>
              <DarkModeToggle
                currentTheme={currentTheme}
                setCurrentTheme={setCurrentTheme}
              />
            </span>
          </Tooltip>
          <MachineInfo theme={currentTheme} />
          <LanguageSwitcher filename={filename} theme={currentTheme} />
          <CompilerVersion theme={currentTheme} version={"0.4.0"} />
          <ShareButton onClick={handleShare} theme={currentTheme} />
        </Stack>
      </Stack>
    </header>
  );
}

export default Header;
