import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

function CompilerVersion({ theme, version }) {
  return (
    <a
      href="https://github.com/lita-xyz/llvm-valida-releases/releases"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Paper
        elevation={0}
        spacing={0}
        sx={{
          borderRadius: "24px",
          backgroundColor: theme.accentBackgroundColor,
          padding: "5px 10px",
        }}
      >
        <Typography sx={{ fontSize: 14, color: theme.headingTextColor }}>
          Version {version}
        </Typography>
      </Paper>
    </a>
  );
}

export default CompilerVersion;
