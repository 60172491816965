import React from "react";
import { Divider } from "@mui/material";
import InputEditor from "../Containers/InputEditor";
import OutputViewer from "../Containers/OutputViewer";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

function RightContainer({
  privateInput,
  setPrivateInput,
  codeValue,
  selectedFile,
  codeOutput,
  setCodeOutput,
  isRunning,
  setIsRunning,
  fileContents,
  currentTheme,
}) {
  return (
    <PanelGroup direction="vertical">
      <Panel maxSize={75}>
        <InputEditor
          privateInput={privateInput}
          setPrivateInput={setPrivateInput}
          currentTheme={currentTheme}
        />
      </Panel>

      <Divider sx={{ borderColor: currentTheme.borderColor }} />
      <PanelResizeHandle />

      <Panel maxSize={75}>
        <OutputViewer
          privateInput={privateInput}
          codeValue={codeValue}
          selectedFile={selectedFile}
          codeOutput={codeOutput}
          setCodeOutput={setCodeOutput}
          isRunning={isRunning}
          fileContents={fileContents}
          setIsRunning={setIsRunning}
          maxRows={15}
          currentTheme={currentTheme}
        />
      </Panel>
    </PanelGroup>
  );
}

export default RightContainer;
