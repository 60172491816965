import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import { Divider } from "@mui/material";
import { StyledTextField } from "../UI/static";
import { ImportInputButton } from "../UI/Buttons";

function InputEditor({ privateInput, setPrivateInput, maxRows, currentTheme }) {
  const [open, setOpen] = React.useState(false);

  const openSnackbar = () => {
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  const onPrivateInputChange = React.useCallback((event) => {
    setPrivateInput(event.target.value);
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPrivateInput(e.target.result);
      };
      reader.onerror = () => {
        console.error("File reading has failed.");
      };
      reader.readAsText(file);
    } else {
      openSnackbar();
    }
  };

  return (
    <>
      <Stack
        sx={{
          backgroundColor: currentTheme.sectionHeadingBackgroundColor,
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="0 8px"
        >
          <Typography
            sx={{
              color: currentTheme.headingTextColor,
              fontSize: 16,
              padding: "0 8px",
            }}
            variant="h6"
          >
            Inputs
          </Typography>
          <Tooltip title="Import Input from File" placement="left">
            <span>
              <ImportInputButton
                onClick={handleFileUpload}
                theme={currentTheme}
              />
            </span>
          </Tooltip>
        </Stack>
        <Box sx={{ height: "1px" }} /> {/* Spacer, prevents off by 1 */}
        <Divider sx={{ borderColor: currentTheme.borderColor }} />
        <Box height="100%">
          <StyledTextField
            sx={{
              height: "100%",
            }}
            variant="outlined"
            placeholder="type here to add inputs"
            fullWidth
            multiline
            rows={maxRows ? null : 12}
            maxRows={maxRows}
            value={privateInput}
            onChange={onPrivateInputChange}
            backgroundColor={currentTheme.backgroundColor}
            textColor={currentTheme.headingTextColor}
          />
        </Box>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        message="File Type Error: Please upload a .txt file"
      />
    </>
  );
}

export default InputEditor;
