import { tags as t } from "@lezer/highlight";
import { createTheme } from "@uiw/codemirror-themes";
import { styled } from "@mui/material/styles";
import { githubLightInit, githubDarkInit } from "@uiw/codemirror-theme-github";
import TextField from "@mui/material/TextField";

export const basicSetupOptions = {
  history: true,
  drawSelection: true,
  foldGutter: true,
  allowMultipleSelections: true,
  bracketMatching: true,
  crosshairCursor: true,
  autocompletion: true,
};

export const playGroundThemes = {
  light: {
    name: "light",
    isDarkMode: false,
    primaryColor: "#F74E00",
    headingTextColor: "#000000",
    secondaryTextColor: "#757575",
    tertiaryTextColor: "#9E9E9E",
    selectedFileBackgroundColor: "#FFEEE5",
    sectionHeadingBackgroundColor: "#FCFCFD",
    backgroundColor: "#FFFFFF",
    accentBackgroundColor: "#F0F0F0",
    secondaryBackgroundColor: "#E2E2E2",
    borderColor: "#E0E0E0",
    secondaryButtonColor: "#1C1B1F",
    iconDir: "/LightTheme",
    codeEditorBackgroundColor: "#FFFFFF",
    codeEditorTheme: githubLightInit({
      settings: {
        gutterBorder: "#FFFFFF",
      },
      styles: [
        {
          tag: [t.keyword, t.typeName, t.typeOperator, t.typeName],
          color: "#F74E00",
        },
      ],
    }),
  },
  dark: {
    name: "dark",
    isDarkMode: true,
    primaryColor: "#FF7434",
    headingTextColor: "#FFFFFF",
    secondaryTextColor: "#D1D1D1",
    tertiaryTextColor: "#9E9E9E",
    sectionHeadingBackgroundColor: "#1C1B1F",
    selectedFileBackgroundColor: "#38373D",
    backgroundColor: "#1C1B1F",
    accentBackgroundColor: "#3B3B3B",
    secondaryBackgroundColor: "#757575",
    borderColor: "#757575",
    secondaryButtonColor: "#D1D1D1",
    iconDir: "/DarkTheme",
    codeEditorBackgroundColor: "#333339",
    codeEditorTheme: githubDarkInit({
      settings: {
        background: "#252525",
      },
      styles: [
        {
          tag: [t.keyword, t.typeName, t.typeOperator, t.typeName],
          color: "#ff7434",
        },
      ],
    }),
  },
};

export const StyledTextField = styled(TextField)(
  ({ backgroundColor, textColor }) => ({
    "& .MuiOutlinedInput-root": {
      backgroundColor: backgroundColor || "#FFFFFF",
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      color: textColor || "#000",
    },
    "&::placeholder": {
      color: "#D1D1D1",
      opacity: 1,
    },
  }),
);

export const codeExamples = {
  "reverse.c": `const unsigned EOF = 0xFFFFFFFF;
#define BUF_LEN 2048

int main() {
    unsigned buf[BUF_LEN];
    unsigned len = 0;
    while (len < BUF_LEN) {
        unsigned c = __builtin_delendum_read_advice();
        if (c == EOF) {
            break;
        } else {
            buf[len] = c;
            len++;
        }
    }
    for (unsigned i = 0; i < len / 2; i++) {
        unsigned j = len - 1 - i;
        unsigned tmp = buf[i];
        buf[i] = buf[j];
        buf[j] = tmp;
    }
    for (unsigned i = 0; i < len; i++) {
        __builtin_delendum_write(buf[i]);
    }
}`,

  "checksum.c": `const unsigned EOF = 0xFFFFFFFF;

int main() {
    unsigned sum = 0;
    while (1) {
        unsigned c = __builtin_delendum_read_advice();
        if (c == EOF) {
            break;
        } else {
          sum += c;
        }
    }
    __builtin_delendum_write(sum);
}`,

  "sha256.c": `// The below code from https://github.com/B-Con/crypto-algorithms/
// was originally written by Brad Conte and has been modified
// to compile and run in Valida.

/****************************** MACROS ******************************/
#define SHA256_BLOCK_SIZE 32            // SHA256 outputs a 32 byte digest

#ifdef __DELENDUM__
#define size_t unsigned int

void * memset ( void * b, int c, size_t len ) {
  int           i;
  unsigned char *p = b;
  i = 0;
  while(len > 0)
    {
      *p = c;
      p++;
      len--;
    }
  return b;
}

#else
#include <stddef.h>
#endif

/**************************** DATA TYPES ****************************/
typedef unsigned char BYTE;             // 8-bit byte
typedef unsigned int  WORD;             // 32-bit word, change to "long" for 16-bit machines

typedef struct {
	BYTE data[64];
	WORD datalen;
	unsigned long long bitlen;
	WORD state[8];
} SHA256_CTX;

/*********************** FUNCTION DECLARATIONS **********************/
void sha256_init(SHA256_CTX *ctx);
void sha256_update(SHA256_CTX *ctx, const BYTE data[], size_t len);
void sha256_final(SHA256_CTX *ctx, BYTE hash[]);

/****************************** MACROS ******************************/
#define ROTLEFT(a,b) (((a) << (b)) | ((a) >> (32-(b))))
#define ROTRIGHT(a,b) (((a) >> (b)) | ((a) << (32-(b))))

#define CH(x,y,z) (((x) & (y)) ^ (~(x) & (z)))
#define MAJ(x,y,z) (((x) & (y)) ^ ((x) & (z)) ^ ((y) & (z)))
#define EP0(x) (ROTRIGHT(x,2) ^ ROTRIGHT(x,13) ^ ROTRIGHT(x,22))
#define EP1(x) (ROTRIGHT(x,6) ^ ROTRIGHT(x,11) ^ ROTRIGHT(x,25))
#define SIG0(x) (ROTRIGHT(x,7) ^ ROTRIGHT(x,18) ^ ((x) >> 3))
#define SIG1(x) (ROTRIGHT(x,17) ^ ROTRIGHT(x,19) ^ ((x) >> 10))

/**************************** VARIABLES *****************************/
static const WORD k[64] = {
	0x428a2f98,0x71374491,0xb5c0fbcf,0xe9b5dba5,0x3956c25b,0x59f111f1,0x923f82a4,0xab1c5ed5,
	0xd807aa98,0x12835b01,0x243185be,0x550c7dc3,0x72be5d74,0x80deb1fe,0x9bdc06a7,0xc19bf174,
	0xe49b69c1,0xefbe4786,0x0fc19dc6,0x240ca1cc,0x2de92c6f,0x4a7484aa,0x5cb0a9dc,0x76f988da,
	0x983e5152,0xa831c66d,0xb00327c8,0xbf597fc7,0xc6e00bf3,0xd5a79147,0x06ca6351,0x14292967,
	0x27b70a85,0x2e1b2138,0x4d2c6dfc,0x53380d13,0x650a7354,0x766a0abb,0x81c2c92e,0x92722c85,
	0xa2bfe8a1,0xa81a664b,0xc24b8b70,0xc76c51a3,0xd192e819,0xd6990624,0xf40e3585,0x106aa070,
	0x19a4c116,0x1e376c08,0x2748774c,0x34b0bcb5,0x391c0cb3,0x4ed8aa4a,0x5b9cca4f,0x682e6ff3,
	0x748f82ee,0x78a5636f,0x84c87814,0x8cc70208,0x90befffa,0xa4506ceb,0xbef9a3f7,0xc67178f2
};

/*********************** FUNCTION DEFINITIONS ***********************/
void sha256_transform(SHA256_CTX *ctx, const BYTE data[])
{
	WORD a, b, c, d, e, f, g, h, i, j, t1, t2, m[64];

	for (i = 0, j = 0; i < 16; ++i, j += 4)
		m[i] = (data[j] << 24) | (data[j + 1] << 16) | (data[j + 2] << 8) | (data[j + 3]);
	for ( ; i < 64; ++i)
		m[i] = SIG1(m[i - 2]) + m[i - 7] + SIG0(m[i - 15]) + m[i - 16];

	a = ctx->state[0];
	b = ctx->state[1];
	c = ctx->state[2];
	d = ctx->state[3];
	e = ctx->state[4];
	f = ctx->state[5];
	g = ctx->state[6];
	h = ctx->state[7];

	for (i = 0; i < 64; ++i) {
		t1 = h + EP1(e) + CH(e,f,g) + k[i] + m[i];
		t2 = EP0(a) + MAJ(a,b,c);
		h = g;
		g = f;
		f = e;
		e = d + t1;
		d = c;
		c = b;
		b = a;
		a = t1 + t2;
	}

	ctx->state[0] += a;
	ctx->state[1] += b;
	ctx->state[2] += c;
	ctx->state[3] += d;
	ctx->state[4] += e;
	ctx->state[5] += f;
	ctx->state[6] += g;
	ctx->state[7] += h;
}

void sha256_init(SHA256_CTX *ctx)
{
	ctx->datalen = 0;
	ctx->bitlen = 0;
	ctx->state[0] = 0x6a09e667;
	ctx->state[1] = 0xbb67ae85;
	ctx->state[2] = 0x3c6ef372;
	ctx->state[3] = 0xa54ff53a;
	ctx->state[4] = 0x510e527f;
	ctx->state[5] = 0x9b05688c;
	ctx->state[6] = 0x1f83d9ab;
	ctx->state[7] = 0x5be0cd19;
}

void sha256_update(SHA256_CTX *ctx, const BYTE data[], size_t len)
{
	WORD i;

	for (i = 0; i < len; ++i) {
		ctx->data[ctx->datalen] = data[i];
		ctx->datalen++;
		if (ctx->datalen == 64) {
			sha256_transform(ctx, ctx->data);
			ctx->bitlen += 512;
			ctx->datalen = 0;
		}
	}
}

void sha256_final(SHA256_CTX *ctx, BYTE hash[])
{
	WORD i;

	i = ctx->datalen;

	// Pad whatever data is left in the buffer.
	if (ctx->datalen < 56) {
		ctx->data[i++] = 0x80;
		while (i < 56)
			ctx->data[i++] = 0x00;
	}
	else {
		ctx->data[i++] = 0x80;
		while (i < 64)
			ctx->data[i++] = 0x00;
		sha256_transform(ctx, ctx->data);
		memset(ctx->data, 0, 56);
	}

	// Append to the padding the total message's length in bits and transform.
	ctx->bitlen += ctx->datalen * 8;
	ctx->data[63] = ctx->bitlen;
	ctx->data[62] = ctx->bitlen >> 8;
	ctx->data[61] = ctx->bitlen >> 16;
	ctx->data[60] = ctx->bitlen >> 24;
	ctx->data[59] = ctx->bitlen >> 32;
	ctx->data[58] = ctx->bitlen >> 40;
	ctx->data[57] = ctx->bitlen >> 48;
	ctx->data[56] = ctx->bitlen >> 56;
	sha256_transform(ctx, ctx->data);

	// Since this implementation uses little endian byte ordering and SHA uses big endian,
	// reverse all the bytes when copying the final state to the output hash.
	for (i = 0; i < 4; ++i) {
		hash[i]      = (ctx->state[0] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 4]  = (ctx->state[1] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 8]  = (ctx->state[2] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 12] = (ctx->state[3] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 16] = (ctx->state[4] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 20] = (ctx->state[5] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 24] = (ctx->state[6] >> (24 - i * 8)) & 0x000000ff;
		hash[i + 28] = (ctx->state[7] >> (24 - i * 8)) & 0x000000ff;
	}
}

#define BUF_LEN 256

#ifdef __DELENDUM__
const unsigned EOF = 0xFFFFFFFF;
#else
#include <stdio.h>
#endif

int main() {
    BYTE buf[BUF_LEN];
    BYTE hash[SHA256_BLOCK_SIZE];
    SHA256_CTX ctx;

    unsigned len = 0;
    while (len < BUF_LEN) {
#ifdef __DELENDUM__
        unsigned c = __builtin_delendum_read_advice();
#else
        unsigned c = getc(stdin);
#endif
        if (c == EOF) {
            break;
        } else {
            buf[len] = (BYTE)c;
            len++;
        }
    }
    sha256_init(&ctx);
    for (size_t i = 0; i < len; i += 64) {
        size_t n = len - i < 64 ? len - i : 64;
        sha256_update(&ctx, buf + i, n);
    }

    sha256_final(&ctx, hash);
    for (size_t i = 0; i < SHA256_BLOCK_SIZE; i++) {
#ifdef __DELENDUM__
        __builtin_delendum_write(hash[i]);
#else
        putc(hash[i], stdout);
#endif
    }
    return 0;
}
`,

  "fibonacci.rs": `#![no_main]

#[no_mangle]
pub fn main() {
    entrypoint::io::println("Please enter a number from 0 to 46:");
    // Read a line from stdin and parse it as an u8.
    let n = loop {
        match entrypoint::io::read_line::<u8>() {
            Ok(num) => break num,
            Err(e) => {
                entrypoint::io::println(&format!("Error reading input: {}. Please try again:", e));
            }
        }
    };
    // n that is larger than 46 will overflow the u32 type.
    if n > 46 {
        entrypoint::io::println("Error: n is too large. Please enter a number no more than 46.");
        return;
    }
    let mut a: u32 = 0;
    let mut b: u32 = 1;
    let mut sum: u32;
    for _ in 1..n {
        sum = a + b;
        a = b;
        b = sum;
    }

    entrypoint::io::println(&n.to_string());
    entrypoint::io::println("-th fibonacci number is:");
    entrypoint::io::println(&b.to_string());
}`,

  "conway.rs": `// This is an example of multi-file compilation.

#![no_main]

use rust_shell::Universe;

#[no_mangle]
pub fn main() {
    let mut universe = Universe::new();
    for i in 0..10 {
        universe.tick();
    }
    for i in 0..10 {
        for j in 0..10 {
            let idx = universe.get_index(i, j);
            let cell = universe.get_cells()[idx] as u8;
            entrypoint::io::println(cell.to_string().as_str())
        }
    }
}`,
"fibonacci.rs" : `#![no_main]

#[no_mangle]
pub fn main() {
    entrypoint::io::println("Please enter a number from 0 to 46:");
    // Read a line from stdin and parse it as an u8.
    let n = loop {
        match entrypoint::io::read_line::<u8>() {
            Ok(num) => break num,
            Err(e) => {
                entrypoint::io::println(&format!("Error reading input: {}. Please try again:", e));
            }
        }
    };
    // n that is larger than 46 will overflow the u32 type.
    if n > 46 {
        entrypoint::io::println("Error: n is too large. Please enter a number no more than 46.");
        return;
    }
    let mut a: u32 = 0;
    let mut b: u32 = 1;
    let mut sum: u32;
    for _ in 1..n {
        sum = a + b;
        a = b;
        b = sum;
    }

    entrypoint::io::println(&n.to_string());
    entrypoint::io::println("-th fibonacci number is:");
    entrypoint::io::println(&b.to_string());
}
`,

"hello_world.rs" : `#![no_main]

#[no_mangle]
pub fn main() {
    entrypoint::io::println("Hello, world!");
}
`,

"lib.rs" : `// This code is adapted from https://github.com/rustwasm/wasm_game_of_life
// by six different contributors, licensed under the MIT and Apache 2.0 licenses.

#![no_std]

#[repr(u8)]
#[derive(Clone, Copy, Debug, PartialEq, Eq)]
pub enum Cell {
    Dead = 0,
    Alive = 1,
}

impl Cell {
    fn toggle(&mut self) {
        *self = match *self {
            Cell::Dead => Cell::Alive,
            Cell::Alive => Cell::Dead,
        };
    }
}

const UNIVERSE_WIDTH: u32 = 10;
const UNIVERSE_HEIGHT: u32 = 10;

pub struct Universe {
    cells: [Cell; 100],
}

impl Universe {
    pub fn get_index(&self, row: u32, column: u32) -> usize {
        (row * UNIVERSE_WIDTH + column) as usize
    }

    /// Get the dead and alive values of the entire universe.
    pub fn get_cells(&self) -> &[Cell] {
        &self.cells
    }

    /// Set cells to be alive in a universe by passing the row and column
    /// of each cell as an array.
    pub fn set_cells(&mut self, cells: &[(u32, u32)]) {
        for (row, col) in cells.iter().cloned() {
            let idx = self.get_index(row, col);
            self.cells[idx] = Cell::Alive;
        }
    }

    fn live_neighbor_count(&self, row: u32, column: u32) -> u8 {
        let mut count = 0;

        let north = if row == 0 {
            UNIVERSE_HEIGHT - 1
        } else {
            row - 1
        };

        let south = if row == UNIVERSE_HEIGHT - 1 {
            0
        } else {
            row + 1
        };

        let west = if column == 0 {
            UNIVERSE_WIDTH - 1
        } else {
            column - 1
        };

        let east = if column == UNIVERSE_WIDTH - 1 {
            0
        } else {
            column + 1
        };

        let nw = self.get_index(north, west);
        count += self.cells[nw] as u8;

        let n = self.get_index(north, column);
        count += self.cells[n] as u8;

        let ne = self.get_index(north, east);
        count += self.cells[ne] as u8;

        let w = self.get_index(row, west);
        count += self.cells[w] as u8;

        let e = self.get_index(row, east);
        count += self.cells[e] as u8;

        let sw = self.get_index(south, west);
        count += self.cells[sw] as u8;

        let s = self.get_index(south, column);
        count += self.cells[s] as u8;

        let se = self.get_index(south, east);
        count += self.cells[se] as u8;

        count
    }
}

/// Public methods, exported to JavaScript.
impl Universe {
    pub fn tick(&mut self) {
        // let _timer = Timer::new("Universe::tick");

        let mut next = self.cells.clone();

        for row in 0..UNIVERSE_HEIGHT {
            for col in 0..UNIVERSE_WIDTH {
                let idx = self.get_index(row, col);
                let cell = self.cells[idx];
                let live_neighbors = self.live_neighbor_count(row, col);

                let next_cell = match (cell, live_neighbors) {
                    // Rule 1: Any live cell with fewer than two live neighbours
                    // dies, as if caused by underpopulation.
                    (Cell::Alive, x) if x < 2 => Cell::Dead,
                    // Rule 2: Any live cell with two or three live neighbours
                    // lives on to the next generation.
                    (Cell::Alive, 2) | (Cell::Alive, 3) => Cell::Alive,
                    // Rule 3: Any live cell with more than three live
                    // neighbours dies, as if by overpopulation.
                    (Cell::Alive, x) if x > 3 => Cell::Dead,
                    // Rule 4: Any dead cell with exactly three live neighbours
                    // becomes a live cell, as if by reproduction.
                    (Cell::Dead, 3) => Cell::Alive,
                    // All other cells remain in the same state.
                    (otherwise, _) => otherwise,
                };

                next[idx] = next_cell;
            }
        }

        self.cells = next;
    }

    pub fn new() -> Universe {
        let width = UNIVERSE_WIDTH;
        let height = UNIVERSE_HEIGHT;

        let mut cells = [Cell::Dead; (UNIVERSE_WIDTH * UNIVERSE_HEIGHT) as usize];

        for i in 0 .. (width * height) {
            if i % 2 == 0 || i % 7 == 0 {
                cells[i as usize] = Cell::Alive;
            }
        }

        Universe {
            cells,
        }
    }

    pub fn width(&self) -> u32 {
        UNIVERSE_WIDTH
    }

    pub fn height(&self) -> u32 {
        UNIVERSE_HEIGHT
    }

    pub fn cells(&self) -> *const Cell {
        self.cells.as_ptr()
    }

    pub fn toggle_cell(&mut self, row: u32, column: u32) {
        let idx = self.get_index(row, column);
        self.cells[idx].toggle();
    }
}
`,
"palindrome.rs": `#![no_main]

#[no_mangle]
pub fn main() {
    entrypoint::io::println("Please enter a word or phrase:");
    let word = match entrypoint::io::read_line::<String>() {
        Ok(w) => w,
        Err(e) => {
            entrypoint::io::println(&format!("Error reading input: {}", e));
            return;
        }
    };
    let word_no_spaces = word.chars().filter(|c| !c.is_whitespace()).collect::<String>();
    let is_palindrome = word_no_spaces.chars().eq(word_no_spaces.chars().rev());
    entrypoint::io::println(&format!("Is '{}' a palindrome? {}", word, is_palindrome));
}
`,
"factorial.rs": `#![no_main]

#[no_mangle]
pub fn main() {
    entrypoint::io::println("Please enter a number from 0 to 12:");
    let num = loop {
        match entrypoint::io::read_line::<u32>() {
            Ok(num) => {
                if num > 12 {
                    entrypoint::io::println("Number must be between 0 and 12. Please try again:");
                    continue;
                }
                break num
            },
            Err(e) => {
                entrypoint::io::println(&format!("Error reading input: {}. Please try again:", e));
            }
        }
    };
    let mut factorial:u32 = 1;
    for i in 1..=num {
        factorial *= i;
    }
    entrypoint::io::println(&format!("Factorial of {} is {}", num, factorial));
}`,
"fizzbuzz.rs": `#![no_main]

#[no_mangle]
pub fn main() {
    entrypoint::io::println("Please enter a number:");
    let n = loop {
        match entrypoint::io::read_line::<u32>() {
            Ok(num) => break num,
            Err(e) => {
                entrypoint::io::println(&format!("Error reading input: {}. Please try again:", e));
            }
        }
    };
    
    for i in 1..=n {
        match (i % 3, i % 5) {
            (0, 0) => entrypoint::io::println("FizzBuzz"),
            (0, _) => entrypoint::io::println("Fizz"),
            (_, 0) => entrypoint::io::println("Buzz"),
            _ => entrypoint::io::println(&format!("{}", i))
        }
    }
}`
};

