import React, { useState, useRef } from "react";
import { Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import CodeMirror from "@uiw/react-codemirror";
import { indentUnit } from "@codemirror/language";
import { cpp, cppLanguage } from "@codemirror/lang-cpp";
import { rust, rustLanguage } from "@codemirror/lang-rust";
import { languages } from "@codemirror/language-data";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { MenuButton, RunButton } from "../UI/Buttons";
import FileDrawer from "../Containers/FileDrawer";
import {
  basicSetupOptions,
} from "../UI/static";
import { vim } from "@replit/codemirror-vim"

function CodeEditor({
  privateInput,
  codeValue,
  setCodeValue,
  selectedFile,
  setSelectedFile,
  setCodeOutput,
  isRunning,
  setIsRunning,
  fileContents,
  setFileContents,
  currentTheme,
}) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [vimToggle, setVimToggle] = useState(false);
  const clickCountRef = useRef(0);
  const clickTimeoutRef = useRef(null);

  const onChange = (value) => {
    setCodeValue(value);
  };

  const handleBlur = () => {
    setFileContents((prevContents) => ({
      ...prevContents,
      [selectedFile]: codeValue,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleBlur();
    }
  };

  const handleTripleClick = () => {
    clickCountRef.current += 1;

    if (clickCountRef.current === 5) {
      console.log("toggling vim mode");
      setVimToggle((prev) => !prev);
      clickCountRef.current = 0;
      clearTimeout(clickTimeoutRef.current);
    } else {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = setTimeout(() => {
        clickCountRef.current = 0;
      }, 300);
    }
  }

  const handleFileClick = (fileName, fileContent = "") => {
    setFileContents((prevContents) => ({
      ...prevContents,
      [selectedFile]: codeValue,
    }));

    setSelectedFile(fileName);

    if (Object.keys(fileContents).some((name) => name === fileName)) {
      setCodeValue(fileContents[fileName] || "");
    } else {
      setCodeValue(fileContent);
    }
  };

  const runCode = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: [
        codeValue,
        ...(fileContents["lib.rs"] ? [fileContents["lib.rs"]] : []),
      ],
      task: "run",
      stdin: privateInput,
      language: selectedFile.slice(-1) === "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      process.env.REACT_APP_BACKEND_LINK + "/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  const ref = useRef(null);

  const togglePanel = () => {
    const panel = ref.current;
    if (panel && drawerOpen) {
      panel.collapse();
      setDrawerOpen(false);
    } else if (panel && !drawerOpen) {
      panel.expand(25);
      setDrawerOpen(true);
    }
  };

  return (
    <PanelGroup direction="horizontal">
      <Panel
        collapsible={true}
        ref={ref}
        defaultSize={30}
        collapsedSize={0}
        minSize={25}
      >
        <FileDrawer
          handleFileClick={handleFileClick}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileContents={fileContents}
          setFileContents={setFileContents}
          currentTheme={currentTheme}
        />
      </Panel>

      <Divider
        orientation="vertical"
        sx={{ borderColor: currentTheme.borderColor }}
      />
      <PanelResizeHandle />

      <Panel minSize={50}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={handleTripleClick}
          sx={{
            padding: "0 4px",
            height: "40px",
            backgroundColor: currentTheme.backgroundColor,
          }}
        >
          <Tooltip
            title={drawerOpen ? "Collapse Drawer" : "Open Drawer"}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <MenuButton onClick={togglePanel} theme={currentTheme} />
            </span>
          </Tooltip>
          <Typography
            sx={{
              fontSize: "16px",
              color: currentTheme.headingTextColor,
            }}
          >
            {selectedFile}
          </Typography>
          <Tooltip
            title="Run Code"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <RunButton
                onClick={runCode}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
                theme={currentTheme}
              />
            </span>
          </Tooltip>
        </Stack>
        <Box sx={{ height: "1px" }} />
        <Divider sx={{ borderColor: currentTheme.borderColor }} />
        <Box
          sx={{
            height: "calc(100% - 40px)",
            overflow: "hidden",
          }}
        >
          <CodeMirror
            value={codeValue}
            placeholder={"Please enter your code here..."}
            height="100%"
            basicSetup={basicSetupOptions}
            theme={currentTheme.codeEditorTheme}
            autoFocus={true}
            onChange={onChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            extensions={[
              selectedFile.slice(-1) === "c"
                ? cpp({ base: cppLanguage, codeLanguages: languages })
                : rust({ base: rustLanguage, codeLanguages: languages }),
              indentUnit.of("    "),
              ...(vimToggle ? [vim()] : []),
            ]}
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          />
        </Box>
      </Panel>
    </PanelGroup>
  );
}

export default CodeEditor;
