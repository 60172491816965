import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Header from "../Containers/Header";
import CodeEditor from "../Containers/CodeEditor";
import RightContainer from "../Containers/RightContainer";
import WelcomeView from "../UI/WelcomeView";
import { codeExamples, playGroundThemes } from "../UI/static";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

function Main({ currentTheme, setCurrentTheme }) {
  const [privateInput, setPrivateInput] = React.useState("");
  const [codeOutput, setCodeOutput] = React.useState("");
  const [codeValue, setCodeValue] = React.useState(
    codeExamples["reverse.c"],
  );
  const [isRunning, setIsRunning] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState("reverse.c");
  const [hasVisited, setHasVisited] = useState(false);
  const [fileContents, setFileContents] = useState({ ...codeExamples });

  useEffect(() => {
    const visited = localStorage.getItem("hasVisited");
    const themePreference = localStorage.getItem("theme");

    if (visited) {
      setHasVisited(true);
    }

    if (themePreference) {
      setCurrentTheme(playGroundThemes[themePreference]);
    } else {
      setCurrentTheme(playGroundThemes["light"]);
    }
  }, []);

  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
    localStorage.setItem("theme", theme.name);
  };

  if (!currentTheme) {
    return null;
  }

  return (
    <Box
      sx={{ height: "100vh", backgroundColor: currentTheme.backgroundColor }}
    >
      {!hasVisited && <WelcomeView theme={currentTheme} />}
      <Stack direction="column" sx={{ height: "100%" }}>
        <Header
          codeValue={codeValue}
          privateInput={privateInput}
          filename={selectedFile}
          currentTheme={currentTheme}
          setCurrentTheme={handleThemeChange}
        />
        <Divider sx={{ borderColor: currentTheme.borderColor }} />

        <PanelGroup direction="horizontal">
          <Panel
            defaultSize={70}
            minSize={20}
            sx={{
              backgroundColor: "red",
            }}
          >
            <CodeEditor
              privateInput={privateInput}
              codeValue={codeValue}
              setCodeValue={setCodeValue}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setCodeOutput={setCodeOutput}
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              fileContents={fileContents}
              setFileContents={setFileContents}
              currentTheme={currentTheme}
            />
          </Panel>
          <Divider
            orientation="vertical"
            sx={{ borderColor: currentTheme.borderColor }}
          />
          <PanelResizeHandle />
          <Panel defaultSize={30} minSize={20} maxSize={50}>
            <RightContainer
              privateInput={privateInput}
              setPrivateInput={setPrivateInput}
              codeValue={codeValue}
              selectedFile={selectedFile}
              codeOutput={codeOutput}
              setCodeOutput={setCodeOutput}
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              fileContents={fileContents}
              currentTheme={currentTheme}
            />
          </Panel>
          <PanelResizeHandle />
        </PanelGroup>
      </Stack>
    </Box>
  );
}

export default Main;
