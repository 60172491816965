import React from "react";
import "./App.css";
import Main from "./components/Pages/Main";

function App({ currentTheme, setCurrentTheme }) {
  return (
    <Main
      currentTheme={currentTheme}
      setCurrentTheme={setCurrentTheme}
    />
  );
}

export default App;
