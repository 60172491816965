import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ProveButton, VerifyButton, DownloadButton } from "../UI/Buttons";
import { StyledTextField } from "../UI/static";

function OutputViewer({
  privateInput,
  codeValue,
  selectedFile,
  codeOutput,
  setCodeOutput,
  isRunning,
  fileContents,
  setIsRunning,
  maxRows,
  currentTheme,
}) {
  const handleProve = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: [
        codeValue, 
      ],
      task: "prove",
      stdin: privateInput,
      language: selectedFile.slice(-1) == "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      process.env.REACT_APP_BACKEND_LINK + "/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  const handleVerify = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: [
        codeValue, 
        ...(fileContents["lib.rs"] ? [fileContents["lib.rs"]] : []),
      ],
      task: "verify",
      stdin: privateInput,
      language: selectedFile.slice(-1) == "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      process.env.REACT_APP_BACKEND_LINK + "/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  const downloadFile = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: [
        codeValue, 
        ...(fileContents["lib.rs"] ? [fileContents["lib.rs"]] : []),
      ],
      task: "prove",
      stdin: privateInput,
      language: selectedFile.slice(-1) == "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      "https://playground-backend.valida.dev:3000/download",
      requestOptions,
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "program.proof";
        document.body.appendChild(a);
        a.click();
        a.remove();
        console.log("Saved proof successfully, big bro.");
      })
      .catch((error) =>
        console.error("Error downloading the .proof file: ", error),
      );
  };

  return (
    <Stack
      sx={{
        backgroundColor: currentTheme.backgroundColor,
        position: "relative",
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="0 8px"
      >
        <Typography
          sx={{
            color: currentTheme.headingTextColor,
            fontSize: 16,
            padding: "0 8px",
          }}
          variant="h6"
        >
          Output
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1} padding={1}>
          <Tooltip
            title="Generate Proof"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <ProveButton
                onClick={handleProve}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
                theme={currentTheme}
              />
            </span>
          </Tooltip>
          <Tooltip
            title="Verify Proof"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <VerifyButton
                aria-label="verify"
                size="small"
                onClick={handleVerify}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
                theme={currentTheme}
              />
            </span>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider sx={{ borderColor: currentTheme.borderColor }} />

      <Box position="relative" height="100%" width="100%">
        <StyledTextField
          sx={{
            height: "100%",
          }}
          backgroundColor={currentTheme.backgroundColor}
          textColor={currentTheme.headingTextColor}
          variant="outlined"
          placeholder="code action result will appear here"
          fullWidth
          multiline
          maxRows={maxRows}
          value={codeOutput}
          InputProps={{
            readOnly: true,
            style: {
              fontSize: "14px",
              fontFamily: '"Fira Code", monospace',
            },
          }}
        />
        <Box position="fixed" bottom={16} right={16}>
          <Tooltip title="Download Proof" placement="left">
            <span>
              <DownloadButton
                onClick={downloadFile}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
                theme={currentTheme}
              />
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Stack>
  );
}

export default OutputViewer;
