import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Switch } from "@mui/material";
import { IconButton } from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { playGroundThemes } from "./static";

function DarkModeToggle({ currentTheme, setCurrentTheme }) {
  const [darkMode, setDarkMode] = useState(currentTheme.isDarkMode);

  const handleToggle = () => {
    setDarkMode(!darkMode);
    setCurrentTheme(
      !darkMode ? playGroundThemes["dark"] : playGroundThemes["light"],
    );
  };

  return (
    <IconButton
      onClick={handleToggle}
      color="inherit"
      sx={{
        color: currentTheme.secondaryTextColor,
        padding: 0,
      }}
      disableRipple
    >
      {darkMode ? (
        <LightModeIcon color="inherit" />
      ) : (
        <DarkModeIcon color="inherit" />
      )}
    </IconButton>
  );
}

export default DarkModeToggle;
